<template>
  <el-drawer v-model="drawer" :close-on-click-modal="false" size="1050px">
    <template #header>
      <div class="header_top">
        <div>{{ operaType == 'renew' ? "续约" : form.id ? '编辑' : "新增" }}</div>
        <div v-if="!form.id || ![3, 4].includes(form.contractState)">
          <el-button type="primary" @click="save" size="small">保存</el-button>
        </div>
      </div>
    </template>
    <el-form :model="form" label-width="100px" :rules="rules" size="small" :inline="true" ref="ruleFormRef">
      <div>
        <el-form-item label="合同类型" prop="contractType">
          <el-radio-group v-model="form.contractType" :disabled="form.id && !form.renewalType" @change="changeType">
            <el-radio :label="0">代账</el-radio>
            <el-radio :label="1">增值</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="客户名称" prop="customerId">
        <select-common placeholder="请选择客户" v-model:modelValue="form.customerId" :options="customerOptions"
          class="w390 ml-mr-0" labelKey="customerName" valueKey="id" @selectChange="changeCustomer"
          :disabled="form.id" />
      </el-form-item>
      <el-form-item label="是否续签" prop="renewalType" v-if="!form.contractType">
        <div class="w145">
          <el-checkbox v-model="form.renewalType" :true-label="1" :false-label="0" :disabled="true" />
        </div>
      </el-form-item>
      <el-form-item label="合同编码" prop="contractNum">
        <el-input v-model="form.contractNum" placeholder="请输入合同编码" class="w145"></el-input>
      </el-form-item>
      <el-form-item label="业务类型" prop="businessTypeId">
        <cascader-common v-model:modelValue="form.businessTypeId" placeholder="请选择业务类型" :options="contractTypeOptions"
          :casProps="casProps" style="width: 390px" :disabled="form.id && !form.renewalType" />
      </el-form-item>
      <el-form-item label="开始日期" prop="contractStartTime">
        <el-date-picker style="width: 145px" value-format="YYYY-MM-DD" type="date" placeholder="请选择开始日期"
          :disabled-date="firstDisabledDate" v-model="form.contractStartTime" @change="changeCycle" :clearable="false"
          :disabled="form.id && !form.renewalType">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期" prop="contractEndTime">
        <el-date-picker style="width: 145px" value-format="YYYY-MM-DD" v-model="form.contractEndTime" type="date"
          placeholder="请选择结束日期" :disabled="form.paymentCycle != '一次性' || (form.id && !form.renewalType)"
          :disabled-date="lastDisabledDate" @change="changeCycle" :clearable="false">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="收款周期" prop="paymentCycle" v-if="!form.contractType">
        <select-common placeholder="请选择收款周期" v-model:modelValue="form.paymentCycle" :options="cycleOptions"
          class="w173 ml0" @selectChange="changeCycle" :disabled="form.id && !form.renewalType" />
        共&nbsp;&nbsp;
        <el-input-number v-model="form.paymentNum" :min="1" controls-position="right" class="w173"
          :disabled="form.paymentCycle == '一次性' || (form.id && !form.renewalType)" @change="changeCycle" />
        &nbsp;&nbsp;期
      </el-form-item>
      <el-form-item label="赠送月份" prop="giveNum" v-if="!form.contractType">
        <el-input-number v-model="form.giveNum" class="w145" @change="changeGive" :min="0"
          :disabled="form.id && !form.renewalType" />
      </el-form-item>
      <el-form-item label="期末收" prop="endTerm" v-if="!form.contractType">
        <div class="w145">
          <el-checkbox v-model="form.endTerm" :disabled="form.paymentCycle == '一次性'" @change="changeEndTerm" />
        </div>
      </el-form-item>
      <el-form-item label="每月服务费" prop="serviceCharge" v-if="!form.contractType">
        <qzf-input v-model:num="form.serviceCharge" @changeLx="changeServiceCharge" class="w145" />
      </el-form-item>
      <el-form-item label="合同总金额" prop="contractAmount">
        <el-input v-model="form.contractAmount" placeholder="请输入合同总金额" class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contactsName">
        <el-input v-model="form.contactsName" placeholder="请输入联系人" class="w145"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="contactsPhone">
        <el-input v-model="form.contactsPhone" placeholder="请输入联系方式" class="w145"></el-input>
      </el-form-item>
      <el-form-item label="是否已签合同" prop="signContract">
        <div class="w145">
          <el-checkbox v-model="form.signContract" @change="changeSign" true-label="1" false-label="0"/>
        </div>
      </el-form-item>
      <el-form-item label="签订日期" prop="contractSignTime">
        <el-date-picker style="width: 145px" value-format="YYYY-MM-DD" v-model="form.contractSignTime" type="date"
          placeholder="请选择签订日期" :disabled="form.signContract == '0'">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="签约人" prop="businessName">
        <el-input v-model="form.businessName" placeholder="请输入签约人" class="w145"></el-input>
      </el-form-item>
      <el-form-item label="服务方式" prop="serviceMode" v-if="!form.contractType">
        <select-common placeholder="请选择服务方式" v-model:modelValue="form.serviceMode" :options="serviceOptions"
          class="w145 ml-mr-0" />
      </el-form-item>
      <el-form-item label="是否期初" prop="beginPeriod">
        <select-common placeholder="请选择是否期初" v-model:modelValue="form.beginPeriod" :options="options"
          class="w145 ml-mr-0" @selectChange="changeBegin" :disabled="form.id && !form.renewalType" />
      </el-form-item>
      <el-form-item label="合同状态" prop="contractState">
        <select-common placeholder="请选择合同状态" v-model:modelValue="form.contractState" :options="stateOptions"
          :disabled="true" class="w145 ml-mr-0" />
      </el-form-item>
      <el-form-item label="录入人" prop="enterName">
        <el-input v-model="form.enterName" placeholder="请输入录入人" class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="录入日期" prop="enterDate">
        <el-date-picker style="width: 145px" value-format="YYYY-MM-DD" v-model="form.enterDate" type="date"
          placeholder="请选择录入日期" disabled>
        </el-date-picker>
      </el-form-item>
      <el-form-item label="最终修改人" prop="contractNo">
        <el-input v-model="form.contractName" placeholder="请输入最终修改人" class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="审核人" prop="auditName">
        <el-input v-model="form.auditName" placeholder="请输入审核人" class="w145" disabled></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark" v-if="!form.contractType">
        <el-input v-model="form.remark" placeholder="请输入备注" class="w390"></el-input>
      </el-form-item>
    </el-form>
    <div class="record">合同附件：</div>
    <FileUploader @change="updateFileList" :initialFileList="form.fileList" />
    <el-card style="width: 100%">
      <template #header>
        <div class="header_top">
          收款计划
          <el-button size="small" @click="addServer(form.newCrmPayment)" type="primary"
            v-if="form.newCrmPayment.length == 0">新增</el-button>
        </div>
      </template>
      <el-table :data="form.newCrmPayment" style="width: 100%" show-summary border>
        <el-table-column label="操作" width="95">
          <template #default="scope">
            <el-button size="small" link @click="addServer(form.newCrmPayment)">新增</el-button>
            <el-button size="small" link type="danger"
              @click="delServer(form.newCrmPayment, scope.$index)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column width="130">
          <template #header> <span class="red">*</span> 应收日期</template>
          <template #default="{ row }">
            <el-date-picker value-format="YYYY-MM-DD" v-model="row.receivableTime" type="date" size="small"
              style="width: 100%">
            </el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="应收月份" width="250" v-if="!form.contractType">
          <template #default="{ row }">
            <el-date-picker v-model="row.paymentStartMonth" type="month" placeholder="起始日期" clearable
              value-format="YYYY-MM" size="small" style="width: 45%" />
            -
            <el-date-picker v-model="row.paymentEndMonth" type="month" placeholder="终止日期" clearable
              value-format="YYYY-MM" size="small" style="width: 45%" />
          </template>
        </el-table-column>
        <el-table-column width="150">
          <template #header> <span class="red">*</span> 费用类型</template>
          <template #default="{ row }">
            <cascader-common v-model:modelValue="row.costTypeId" placeholder="请选择费用类型" :options="paymentTypeOptions"
              :casProps="casProps" />
          </template>
        </el-table-column>
        <el-table-column prop="contractDiscount" label="合同优惠" min-width="100">
          <template #default="{ row }">
            <qzf-input v-model:num="row.contractDiscount" @changeLx="changeDiscountAmount(row)" />
          </template>
        </el-table-column>
        <el-table-column prop="receivableAmount" min-width="100">
          <template #header> <span class="red">*</span> 应收金额</template>
          <template #default="{ row }">
            <qzf-input v-model:num="row.receivableAmount" @changeLx="changeReceivableAmount(row)" />
          </template></el-table-column>
        <el-table-column prop="receivedAmount" label="已收金额" min-width="100">
          <template #default="{ row }">
            <qzf-input v-model:num="row.receivedAmount" :disabled="true" /> </template></el-table-column>
        <el-table-column prop="paymentDiscount" label="收款优惠" min-width="100">
          <template #default="{ row }">
            <qzf-input v-model:num="row.paymentDiscount" :disabled="true" /> </template></el-table-column>
        <el-table-column prop="notReceivedAmount" label="未收金额" min-width="100">
          <template #default="{ row }">
            <qzf-input v-model:num="row.notReceivedAmount" :disabled="true" /> </template></el-table-column>
      </el-table>
    </el-card>
  </el-drawer>
</template>

<script setup>
import { ref, defineExpose, onMounted, getCurrentInstance } from "vue";
import { ElMessage, dayjs } from "element-plus";
import {
  customerList,
  contractTypeList,
  contractPaymentTypeList,
  saveContract,
  contractInfo,
  renewalContract
} from "@/api/newCrm.js";
import {
  COLLECTION_CYCLE,
  SERVICE_OPTION,
  YES_OR_NO,
  CONTRACT_STATUS,
} from "@/utils/commonData";
import {
  getMonthFirstAndLastDay,
  getCurrentDate,
  getEndDate,
  getMonthsDifference,
  getLastDayOfMonth,
  getNextMonthFirstDay
} from "@/utils/fun";
import FileUploader from "@/components/uploadImg/FileUploader.vue";

const { proxy } = getCurrentInstance();
const emit = defineEmits();
const drawer = ref(false);
const form = ref({
  contractType: 0,
  newCrmPayment: [
    { receivableTime: "", contractDiscount: 0, receivedAmount: 0, notReceivedAmount: 0, paymentDiscount: 0, receivableAmount: 0 },
  ],
  contractStartTime: "",
  contractEndTime: "",
  paymentCycle: "一次性",
  paymentNum: 1,
  giveNum: 0,
  enterDate: "",
  signContract: "0",
  beginPeriod: 0,
  contractState: 1,
  contractSignTime: "",
  auditStatus: 1,
  fileList: [],
  renewalType: 0
});
const cycleOptions = ref(COLLECTION_CYCLE);
const serviceOptions = ref(SERVICE_OPTION);
const options = ref(YES_OR_NO);
const stateOptions = ref(CONTRACT_STATUS);
const contractTypeOptions = ref([]);
const paymentTypeOptions = ref([]);
const customerOptions = ref([]);
const casProps = ref({
  expandTrigger: "hover",
  label: "name",
  value: "id",
});
const ruleFormRef = ref();
const rules = ref({
  customerId: [{ required: true, message: "请输入客户名称", trigger: "blur" }],
  businessTypeId: [
    { required: true, message: "请选择业务类型", trigger: "change" },
  ],
  contractStartTime: [
    { required: true, message: "请选择开始日期", trigger: "change" },
  ],
  contractEndTime: [
    { required: true, message: "请选择结束日期", trigger: "change" },
  ],
});
const operaType = ref("")
onMounted(() => {
  contractPaymentTypeList({}).then((res) => {
    paymentTypeOptions.value = res.data.data.list;
  });
});
const changeType = () => {
  form.value.businessTypeId = null;
  getContractTypeList()
};

function getContractTypeList() {
  let type = form.value.contractType == 1 ? '2' : '1';
  contractTypeList({ contractType: type }).then((res) => {
    contractTypeOptions.value = res.data.data.list;
  });
}
const firstDisabledDate = (time) => {
  const date = time.getDate();
  return date !== 1;
};
const lastDisabledDate = (time) => {
  const date = time.getDate();
  const lastDay = new Date(
    time.getFullYear(),
    time.getMonth() + 1,
    0
  ).getDate();
  return date !== lastDay;
};
const init = (item, flag) => {
  getCustomerList();
  reset();
  operaType.value = flag
  if (item) {
    contractInfo({ id: item.id }).then((res) => {
      if (res.data.msg == "success") {
        form.value = Object.assign({}, res.data.data.list);
        if (flag) {
          form.value.renewalType = 1
          form.value.newCrmPayment = [{}]
          form.value.contractStartTime = getNextMonthFirstDay(form.value.contractEndTime)
          form.value.newCrmPayment[0].receivableTime = form.value.contractStartTime;
          changeCycle()
        }
        if (form.value.contractFile) {
          let arr = form.value.contractFile.split(",")
          form.value.fileList = arr.map(v => {
            const ext = v.split(".").pop();
            return {
              name: v.split("/").pop(),
              url: v,
              type: ext,
            }
          });
        } else {
          form.value.fileList = []
        }
        getContractTypeList();
      }
    })
  } else {
    const { firstDay, lastDay } = getMonthFirstAndLastDay();
    form.value.contractStartTime = firstDay;
    form.value.contractEndTime = lastDay;
    form.value.newCrmPayment[0].receivableTime = firstDay;
    form.value.enterDate = getCurrentDate();
    form.value.enterName = proxy.$store.getters["user/user"].cnName;
    getContractTypeList();
  }
  drawer.value = true;
};
const getCustomerList = () => {
  customerList({ customerType: "2" }).then((res) => {
    if (res.data.msg == "success") {
      customerOptions.value = res.data.data.list;
    }
  });
};
const changeCustomer = () => {
  let info = customerOptions.value.find(
    (item) => item.id == form.value.customerId
  );
  form.value.contactsName = info?.linkman;
  form.value.contactsPhone = info?.linkmanTel;
  form.value.serviceMode = info?.serviceMode;
};
const addServer = (list) => {
  list.push({
    receivableTime: getLastDayOfMonth(
      form.value.contractStartTime,
      form.value.endTerm
    ),
    receivableAmount: form.value.serviceCharge ? form.value.serviceCharge * filterCycle() : 0,
    notReceivedAmount: form.value.serviceCharge ? form.value.serviceCharge * filterCycle() : 0
  });
};
const delServer = (list, index) => {
  list.splice(index, 1);
};

//修改收款各项
const changeCycle = () => {
  if (form.value.paymentCycle == "一次性") {
    form.value.paymentNum = 1;
  } else {
    form.value.contractEndTime = getEndDate(
      form.value.contractStartTime,
      form.value.paymentCycle,
      form.value.paymentNum,
      form.value.giveNum
    );
  }
  form.value.newCrmPayment = []
  changeTablePayment(form.value.paymentNum)
};
//编辑赠送月份
const changeGive = (newValue, oldValue) => {
  if (form.value.paymentCycle == "一次性") {
    let poor = newValue - oldValue;
    let a = form.value.contractEndTime.slice(0, 7) + "-01";
    form.value.contractEndTime = getEndDate(
      a,
      form.value.paymentCycle,
      form.value.paymentNum,
      poor
    );
  } else {
    changeCycle();
  }
  changeServiceCharge();
};
const changeTablePayment = (flag) => {
  let amount = form.value.serviceCharge
    ? form.value.serviceCharge * filterCycle()
    : 0
  if (flag > 0) {
    for (let i = 0; i < flag; i++) {
      if (i === 0) {
        form.value.newCrmPayment.push({
          receivableTime: getLastDayOfMonth(
            form.value.contractStartTime,
            form.value.endTerm
          ),
          contractDiscount: 0,
          receivableAmount: amount,
          receivedAmount: 0,
          notReceivedAmount: amount
        })
      } else {
        const previous = form.value.newCrmPayment[i - 1]
        let number = filterCycle()
        const isEnd = form.value.endTerm ? 'endOf' : 'startOf'
        form.value.newCrmPayment.push({
          receivableTime: dayjs(previous.receivableTime).add(number, 'month')[isEnd]('month').format('YYYY-MM-DD'),
          contractDiscount: 0,
          receivableAmount: amount,
          receivedAmount: 0,
          notReceivedAmount: amount
        })
      }
    }
  } else {
    let count = Math.abs(flag);
    form.value.newCrmPayment.splice(form.value.newCrmPayment.length - count, count);
  }
  // let lastArr = form.value.newCrmPayment[form.value.newCrmPayment.length - 1].receivableTime
  // form.value.contractEndTime = form.value.endTerm ? lastArr : dayjs(lastArr).endOf('month').format('YYYY-MM-DD')
  changeServiceCharge()
};
const filterCycle = () => {
  let monthsToAdd = 1;
  switch (form.value.paymentCycle) {
    case "季":
      monthsToAdd = 3;
      break;
    case "半年":
      monthsToAdd = 6;
      break;
    case "年":
      monthsToAdd = 12;
      break;
    case "2年":
      monthsToAdd = 24;
      break;
  }
  return monthsToAdd;
};
//修改服务费
const changeServiceCharge = () => {
  let monthPoor = getMonthsDifference(
    form.value.contractStartTime,
    form.value.contractEndTime
  );
  let amount = form.value.serviceCharge
    ? form.value.serviceCharge * filterCycle()
    : 0
  let total = monthPoor * 1 - form.value.giveNum;
  form.value.contractAmount = form.value.serviceCharge
    ? Number((total * form.value.serviceCharge).toFixed(2))
    : 0;
  if (form.value.newCrmPayment) {
    form.value.newCrmPayment.map((v) => {
      v.receivableAmount = amount - (v.contractDiscount ? v.contractDiscount * 1 : 0);
      v.notReceivedAmount = amount - (v.contractDiscount ? v.contractDiscount * 1 : 0) - (v.paymentDiscount ? v.paymentDiscount * 1 : 0) - (v.receivedAmount ? v.receivedAmount * 1 : 0);
    });
  }
};
//修改是否已签合同
const changeSign = () => {
  form.value.contractSignTime =
    form.value.signContract == "1" ? getCurrentDate() : "";
};
//修改是否期初
const changeBegin = () => {
  form.value.contractState = form.value.beginPeriod == 1 ? 2 : 1;
};
//修改应收金额时
const changeReceivableAmount = (row) => {
  row.notReceivedAmount = (row.receivableAmount || 0) - (row.receivedAmount || 0);
  if (!form.value.serviceCharge && row.receivableAmount) {
    form.value.serviceCharge = row.receivableAmount;
    changeServiceCharge();
  }
};
//修改合同优惠时
const changeDiscountAmount = (row) => {
  if (form.value.serviceCharge) {
    row.receivableAmount =
      form.value.serviceCharge * filterCycle() - row.contractDiscount;
  }
  if (row.receivableAmount) {
    row.notReceivedAmount =
      row.receivableAmount - (row.receivedAmount ? row.receivableAmount : 0);
  }
};
//修改是否期末
const changeEndTerm = () => {
  form.value.newCrmPayment.map((v) => {
    v.receivableTime = getLastDayOfMonth(v.receivableTime, form.value.endTerm);
  });
};
//上传合同附件
const updateFileList = (e) => {
  form.value.fileList = e;
};
//保存
const save = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      let arr = form.value.fileList?.map((v) => {
        return v.url;
      });
      form.value.contractFile = arr?.join(",");
      let fail = form.value.newCrmPayment.some(v =>
        !v.receivableTime || !v.costTypeId || !v.receivableAmount
      )
      if (fail) {
        ElMessage.warning('请检查收款计划是否填写完整')
        return
      }
      if (operaType.value == 'renew') {
        renewalContract(form.value).then((res) => {
          if (res.data.msg == "success") {
            ElMessage.success("保存成功");
            drawer.value = false;
            emit("success");
          }
        });
      } else {
        saveContract(form.value).then((res) => {
          if (res.data.msg == "success") {
            ElMessage.success("保存成功");
            drawer.value = false;
            emit("success");
          }
        });
      }
    }
  });
};
const reset = () => {
  form.value = {
    contractType: 0,
    newCrmPayment: [
      { receivableTime: "", contractDiscount: 0, receivedAmount: 0, receivableAmount: 0 },
    ],
    contractStartTime: "",
    contractEndTime: "",
    paymentCycle: "一次性",
    paymentNum: 1,
    giveNum: 0,
    enterDate: "",
    signContract: "0",
    beginPeriod: 0,
    contractState: 1,
    contractSignTime: "",
    auditStatus: 1,
    fileList: [],
    renewalType: 0

  };
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.header_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w145 {
  width: 145px !important;
}

.w173 {
  width: 173px !important;
}

.w390 {
  width: 390px !important;
}

.w880 {
  width: 880px;
}

.el-form--inline .el-form-item {
  margin-right: 0px;
}

.ml0 {
  margin-left: 0px;
}

.ml-mr-0 {
  margin: 0;
}

:deep(.el-card__header) {
  padding: 10px 20px;
}

.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}

.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}

:deep(.el-upload-dragger) {
  padding: 10px !important;
}
</style>
