<template>
  <el-upload
    drag
    action="https://public.listensoft.net/api/uploadImg"
    :accept="accept"
    :file-list="fileListPass"
    :http-request="customUpload"
    :on-success="handleSuccess"
    :on-error="handleError"
    :before-upload="beforeUpload"
    :on-remove="handleRemove"
    :on-preview="previewFile"
    :multiple="multiple"
    :disabled="dis"
  >
    <slot name="icon">
      <el-icon class="el-icon--upload"><upload-filled /></el-icon>
    </slot>
    <slot name="text">
      <div class="el-upload__text">拖拽{{ accept == '.jpg, .png, .jpeg'?'图片':fileName }}到此处或 <em>点击上传</em></div>
    </slot>
  </el-upload>
  <el-image-viewer
    v-if="showImagePreview"
    :zoom-rate="1.2"
    @close="showImagePreview = false"
    :url-list="imgSrc"
    :initial-index="currentIndex"
  />
</template>
<script setup>
import { ref,computed, watch } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
const emit = defineEmits(["change"]);
const props = defineProps({
  initialFileList: {
    type: Array,
    default: () => [],
  },
  accept: {
    type: String,
    default: ".xlsx, .xls, .pdf, .doc, .docx, .jpg, .png, .jpeg",
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  dis: {
    type: Boolean,
    default: false,
  },
});

const showImagePreview = ref(false)
const fileListPass = ref([])
const imgSrc = ref('')
const fileName = ref('文件')
const currentIndex = ref(0)

watch(() => props.initialFileList, (newValue) => {
  fileListPass.value = newValue
})
// const fileListPass = computed(()=>{
//   return props.initialFileList
// })
const baseUrl = ref("https://file.listensoft.net");
//上传文件
const customUpload = (options) => {
  const { action, file, onSuccess, onError } = options;
  console.log(action);

  const formData = new FormData();
  let title = file.name.replace(/,/g, "");
  formData.append("upFile", file, title);
  let config = { headers: { "Content-Type": "multipart/form-data" } };
  axios
    .post(action, formData, config)
    .then((res) => {
      if (res.data.msg == "success") {
        let url = baseUrl.value + res.data.data.url;
        const ext = url.split(".").pop();
        fileListPass.value.push({ url, name: url.split("/").pop(), type: ext });
        onSuccess(res);
      }
    })
    .catch((error) => {
      onError(error);
    });
};
const handleSuccess = (response, file) => {
  file.url = baseUrl.value + response.data.data.url;
  props.accept == '.jpg, .png, .jpeg' ? ElMessage.success("图片上传成功") : ElMessage.success("文件上传成功");
  emit("change", fileListPass.value);
};

const handleError = () => {
  props.accept == '.jpg, .png, .jpeg' ? ElMessage.error("图片上传失败") : ElMessage.error("文件上传失败");
};
const beforeUpload = (file) => {
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    ElMessage.error("文件大小不能超过10MB");
  }
  return isLt10M;
};
const handleRemove = (file) => {
  fileListPass.value = fileListPass.value.filter((f) => f.url != file.url);
  props.accept == '.jpg, .png, .jpeg' ? ElMessage.success("图片删除成功") : ElMessage.success("文件删除成功");
  emit("change", fileListPass.value);
};

function previewFile(item) {
  if (!['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(item.type)) {
    window.open(item.url)
  } else {
    imgSrc.value = [item.url]
    showImagePreview.value = true
  }
}
</script>
